import axios from "axios";
import localStorageService from "./localStorageService";

class WmAuthService {
  loginUsuarioSenha = (usuario, senha) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/signin/${usuario}/${senha}`, {
          headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((retorno) => {
          const { status, data } = retorno;

          if (status === 200) {
            resolve(data);
          } else {
            throw new Error("Usuário inválido");
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
      .then((data) => {
        //isso deve ser ajustado
        //this.setSession(data[0].cdUsuario);
        //this.setUser(data[0]);
        return data;
      })
      .catch((error) => {
        this.setSession(false);
        this.removeUser();
        return null;
      });
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem("wm_token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      localStorage.removeItem("wm_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };

  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new WmAuthService();
