import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaUsuarios = lazy(() => import("./consulta"));
const CadastroUsuario = lazy(() => import("./cadastro"));

const usuarioRoutes = [
  {
    path: "/usuario/consulta",
    component: ConsultaUsuarios,
    auth: authRoles.admin,
  },
  {
    path: "/usuario/cadastro",
    component: CadastroUsuario,
    auth: authRoles.admin,
  },
];

export default usuarioRoutes;