import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Admin = lazy(() => import("./admin/admin"));

const Professor = lazy(() => import("./professor/professor"));

const dashboardRoutes = [
  {
    path: "/dashboard/admin",
    component: Admin,
    auth: [...authRoles.secretaria, ...authRoles.financeiro],
  },
  {
    path: "/dashboard/professor",
    component: Professor,
    auth: authRoles.professor,
  },
];

export default dashboardRoutes;
