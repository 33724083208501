import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroAluno = lazy(() => import("./cadastro"));
const ConsultaAluno = lazy(() => import("./consulta"));
const Desmatriculados = lazy(() => import("./desmatriculados"));
const ImpressaoContrato = lazy(() => import("./contrato"));
const ImpressaoFichaAluno = lazy(() => import("./fichaAluno"));
const ConsultaPreCadastroAluno = lazy(() => import("./consultaPreCadastro"));

const alunoRoutes = [
  {
    path: "/aluno/cadastro",
    component: CadastroAluno,
    auth: authRoles.secretaria,
  },
  {
    path: "/aluno/consulta",
    component: ConsultaAluno,
    auth: authRoles.secretaria,
  },
  {
    path: "/aluno/desmatriculados",
    component: Desmatriculados,
    auth: authRoles.secretaria,
  },
  {
    path: "/aluno/contrato",
    component: ImpressaoContrato,
    auth: authRoles.secretaria,
  },
  {
    path: "/aluno/fichaAluno",
    component: ImpressaoFichaAluno,
    auth: authRoles.secretaria,
  },
  {
    path: "/aluno/consultaPreCadastro",
    component: ConsultaPreCadastroAluno,
    auth: authRoles.secretaria,
  },  
];

export default alunoRoutes;
