import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaContaContabil = lazy(() => import("./consulta"));
const CadastroContaContabil = lazy(() => import("./cadastro"));

const contacontabilRoutes = [
  {
    path: "/contacontabil/consulta",
    component: ConsultaContaContabil,
    auth: authRoles.financeiro,
  },
  {
    path: "/contacontabil/Cadastro",
    component: CadastroContaContabil,
    auth: authRoles.financeiro,
  },
];

export default contacontabilRoutes;