import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroRefeicao = lazy(() => import("./cadastro"));

const refeicaoRoutes = [
  {
    path: "/refeicao/cadastro",
    component: CadastroRefeicao,
    auth: authRoles.secretaria,
  },
];

export default refeicaoRoutes;
