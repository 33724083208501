import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroAtividade = lazy(() => import("./cadastro"));

const atividadeRoutes = [
  {
    path: "/atividade/cadastro",
    component: CadastroAtividade,
    auth: authRoles.secretaria,
  },
];

export default atividadeRoutes;
