import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaConvenios = lazy(() => import("./consulta"));
const CadastroConvenio = lazy(() => import("./cadastro"));

const convenioRoutes = [
  {
    path: "/convenio/consulta",
    component: ConsultaConvenios,
    auth: authRoles.secretaria,
  },
  {
    path: "/convenio/cadastro",
    component: CadastroConvenio,
    auth: authRoles.secretaria,
  },
];

export default convenioRoutes;