import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Rematricula = lazy(() => import("./consulta"));

const rematriculaRoutes = [
  {
    path: "/rematricula/consulta",
    component: Rematricula,
    auth: authRoles.secretaria,
  },
];

export default rematriculaRoutes;