import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroLivroCaixa = lazy(() => import("./cadastro"));
const ImpressaoLivroCaixa = lazy(() => import("./impressao"));

const livrocaixaRoutes = [
  {
    path: "/livrocaixa/cadastro",
    component: CadastroLivroCaixa,
    auth: authRoles.financeiro,
  },
  {
    path: "/livrocaixa/impressao",
    component: ImpressaoLivroCaixa,
    auth: authRoles.financeiro,
  },  
];

export default livrocaixaRoutes;