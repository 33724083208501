import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroEscola = lazy(() => import("./cadastro"));
const EscolaParametros = lazy(() => import("./parametros"));

const escolaRoutes = [
  {
    path: "/escola/cadastro",
    component: CadastroEscola,
    auth: authRoles.secretaria,
  },
  {
    path: "/escola/parametros",
    component: EscolaParametros,
    auth: authRoles.secretaria,
  },
];

export default escolaRoutes;
