import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaCardapios = lazy(() => import("./consulta"));
const CadastroCardapio = lazy(() => import("./cadastro"));

const cardapioRoutes = [
  {
    path: "/cardapio/consulta",
    component: ConsultaCardapios,
    auth: authRoles.secretaria,
  },
  {
    path: "/cardapio/Cadastro",
    component: CadastroCardapio,
    auth: authRoles.secretaria,
  },
];

export default cardapioRoutes;