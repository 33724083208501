import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaRemessa = lazy(() => import("./remessa"));
const ConsultaRetorno = lazy(() => import("./retorno"));
const ConsultaBoletos = lazy(() => import("./boletos"));

const boletosRoutes = [
  {
    path: "/boletos/remessa",
    component: ConsultaRemessa,
    auth: authRoles.financeiro,
  },
  {
    path: "/boletos/retorno",
    component: ConsultaRetorno,
    auth: authRoles.financeiro,
  },
  {
    path: "/boletos/boletos",
    component: ConsultaBoletos,
    auth: authRoles.financeiro,
  },    
];

export default boletosRoutes;
