import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaComunicados = lazy(() => import("./consulta"));
const CadastroComunicado = lazy(() => import("./cadastro"));

const comunicadoRoutes = [
  {
    path: "/comunicado/consulta",
    component: ConsultaComunicados,
    auth: authRoles.secretaria,
  },
  {
    path: "/comunicado/cadastro",
    component: CadastroComunicado,
    auth: authRoles.secretaria,
  },
];

export default comunicadoRoutes;