import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaPortadores = lazy(() => import("./consulta"));
const CadastroPortador = lazy(() => import("./cadastro"));

const portadorRoutes = [
  {
    path: "/portador/consulta",
    component: ConsultaPortadores,
    auth: authRoles.financeiro,
  },
  {
    path: "/portador/cadastro",
    component: CadastroPortador,
    auth: authRoles.financeiro,
  },
];

export default portadorRoutes;