import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaContaPagar = lazy(() => import("./consulta"));
const CadastroContaPagar = lazy(() => import("./cadastro"));

const contapagarRoutes = [
  {
    path: "/contapagar/consulta",
    component: ConsultaContaPagar,
    auth: authRoles.financeiro,
  },
  {
    path: "/contapagar/cadastro",
    component: CadastroContaPagar,
    auth: authRoles.financeiro,
  },
];

export default contapagarRoutes;