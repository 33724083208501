import React from "react";

class localStorageService {
  setItem(key, value) {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
    return true;
  }

  getItem(key) {
    let value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  removeItem = (key) => {
    localStorage.removeItem(key);
  };
}

export default new localStorageService();
