import { lazy } from "react";

const LogIn = lazy(() => import("./login"));
const PreCadastroAluno = lazy(() => import("../aluno/preCadastro"));

const sessionsRoutes = [
  {
    path: "/sessions/login",
    component: LogIn
  },
  {
    path: "/pre-cadastro-aluno/:token",
    component: PreCadastroAluno
  }  
];

export default sessionsRoutes;
