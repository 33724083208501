import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroTurma = lazy(() => import("./cadastro"));
const ConsultaTurma = lazy(() => import("./consulta"));

const turmaRoutes = [
  {
    path: "/turma/cadastro",
    component: CadastroTurma,
    auth: authRoles.professor,
  },
  {
    path: "/turma/consulta",
    component: ConsultaTurma,
    auth: authRoles.professor,
  },
];

export default turmaRoutes;
