import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroLivro = lazy(() => import("./cadastro"));
const ConsultaLivro = lazy(() => import("./consulta"));
const Movimentacao = lazy(() => import("./movimentacao"));
const Movimentacoes = lazy(() => import("./movimentacoes"));

const bibliotecaRoutes = [
  {
    path: "/biblioteca/cadastro",
    component: CadastroLivro,
    auth: authRoles.secretaria,
  },
  {
    path: "/biblioteca/consulta",
    component: ConsultaLivro,
    auth: authRoles.secretaria,
  },
  {
    path: "/biblioteca/movimentacao",
    component: Movimentacao,
    auth: [...authRoles.secretaria, ...authRoles.professor],
  },
  {
    path: "/biblioteca/movimentacoes",
    component: Movimentacoes,
    auth: [...authRoles.secretaria, ...authRoles.professor],
  },
];

export default bibliotecaRoutes;
