import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaAniversariantes = lazy(() =>
  import("./aniversariantes/consulta")
);
const ImpressaoAniversariantes = lazy(() =>
  import("./aniversariantes/impressao")
);
const ConsultaQuadroResumo = lazy(() => import("./quadroresumo/consulta"));
const ImpressaoQuadroResumo = lazy(() => import("./quadroresumo/impressao"));
const ResumoAlunos = lazy(() => import("./resumoalunos/consulta"));
const ImpressaoResumoAlunos = lazy(() => import("./resumoalunos/impressao"));
const RelatorioContaReceber = lazy(() => import("./contareceber/consulta"));
const ImpressaoContaReceber = lazy(() => import("./contareceber/impressao"));
const RelatorioCardapio = lazy(() => import("./cardapio/consulta"));
const ImpressaoCardapio = lazy(() => import("./cardapio/impressao"));
const RelatorioLivroCaixa = lazy(() => import("./livrocaixa/consulta"));
const ImpressaoLivroCaixa = lazy(() => import("./livrocaixa/impressao"));
const RelatorioParecerDescritivo = lazy(() =>
  import("./parecerdescritivo/consulta")
);
const ImpressaoParecerDescritivo = lazy(() =>
  import("./parecerdescritivo/impressao")
);
const ImpressaoLivros = lazy(() => import("./livros/impressao"));
const RelatorioAlunosMatriculados = lazy(() =>
  import("./alunosmatriculados/consulta")
);
const ImpressaoAlunosMatriculados = lazy(() =>
  import("./alunosmatriculados/impressao")
);

const consultaRoutes = [
  {
    path: "/consultas/aniversariantes/consulta",
    component: ConsultaAniversariantes,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/aniversariantes/impressao",
    component: ImpressaoAniversariantes,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/quadroresumo/consulta",
    component: ConsultaQuadroResumo,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/quadroresumo/impressao",
    component: ImpressaoQuadroResumo,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/resumoalunos/consulta",
    component: ResumoAlunos,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/resumoalunos/impressao",
    component: ImpressaoResumoAlunos,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/contareceber/consulta",
    component: RelatorioContaReceber,
    auth: authRoles.financeiro,
  },
  {
    path: "/consultas/contareceber/impressao",
    component: ImpressaoContaReceber,
    auth: authRoles.financeiro,
  },
  {
    path: "/consultas/cardapio/consulta",
    component: RelatorioCardapio,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/cardapio/impressao",
    component: ImpressaoCardapio,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/livrocaixa/consulta",
    component: RelatorioLivroCaixa,
    auth: authRoles.financeiro,
  },
  {
    path: "/consultas/livrocaixa/impressao",
    component: ImpressaoLivroCaixa,
    auth: authRoles.financeiro,
  },
  {
    path: "/consultas/parecerdescritivo/consulta",
    component: RelatorioParecerDescritivo,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/parecerdescritivo/impressao",
    component: ImpressaoParecerDescritivo,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/livros/impressao",
    component: ImpressaoLivros,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/alunosmatriculados/consulta",
    component: RelatorioAlunosMatriculados,
    auth: authRoles.secretaria,
  },
  {
    path: "/consultas/alunosmatriculados/impressao",
    component: ImpressaoAlunosMatriculados,
    auth: authRoles.secretaria,
  },
];

export default consultaRoutes;
