import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaContaReceber = lazy(() => import("./consulta"));
const CadastroContaReceber = lazy(() => import("./cadastro"));
const ImpressaoRecibo = lazy(() => import("./recibo"));

const contareceberRoutes = [
  {
    path: "/contareceber/consulta",
    component: ConsultaContaReceber,
    auth: authRoles.financeiro,
  },
  {
    path: "/contareceber/cadastro",
    component: CadastroContaReceber,
    auth: authRoles.financeiro,
  },
  {
    path: "/contareceber/recibo",
    component: ImpressaoRecibo,
    auth: authRoles.financeiro,
  },  
];

export default contareceberRoutes;