import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaFornecedores = lazy(() => import("./consulta"));
const CadastroFornecedor = lazy(() => import("./cadastro"));

const fornecedorRoutes = [
  {
    path: "/fornecedor/consulta",
    component: ConsultaFornecedores,
    auth: authRoles.financeiro,
  },
  {
    path: "/fornecedor/Cadastro",
    component: CadastroFornecedor,
    auth: authRoles.financeiro,
  },
];

export default fornecedorRoutes;