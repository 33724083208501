import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaConsideracoes = lazy(() => import("./consulta"));
const ImpressaoConsideracoes = lazy(() => import("./impressao"));

const consideracoesRoutes = [
  {
    path: "/consideracao/consulta",
    component: ConsultaConsideracoes,
    auth: authRoles.secretaria,
  },
  {
    path: "/consideracao/impressao",
    component: ImpressaoConsideracoes,
    auth: authRoles.secretaria,
  },  
];

export default consideracoesRoutes;