import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaPlanejamentos = lazy(() => import("./consulta"));
const CadastroPlanejamentos = lazy(() => import("./cadastro"));
const ModeracaoPlanejamentos = lazy(() => import("./moderacao"));
const ImpressaoPlanejamentos = lazy(() => import("./impressao"));

const planejamentoRoutes = [
  {
    path: "/planejamento/consulta",
    component: ConsultaPlanejamentos,
    auth: authRoles.secretaria,
  },
  {
    path: "/planejamento/cadastro",
    component: CadastroPlanejamentos,
    auth: authRoles.secretaria,
  },
  {
    path: "/planejamento/moderacao",
    component: ModeracaoPlanejamentos,
    auth: authRoles.secretaria,
  },
  {
    path: "/planejamento/impressao",
    component: ImpressaoPlanejamentos,
    auth: authRoles.secretaria,
  },    
];

export default planejamentoRoutes;