import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaVisitas = lazy(() => import("./consulta"));
const CadastroVisita = lazy(() => import("./cadastro"));

const visitaRoutes = [
  {
    path: "/visita/consulta",
    component: ConsultaVisitas,
    auth: authRoles.secretaria,
  },
  {
    path: "/visita/Cadastro",
    component: CadastroVisita,
    auth: authRoles.secretaria,
  },
];

export default visitaRoutes;