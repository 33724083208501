import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaParecerDescritivo = lazy(() => import("./consulta"));

const parecerDescritivoRoutes = [
  {
    path: "/parecerdescritivo/consulta",
    component: ConsultaParecerDescritivo,
    auth: authRoles.secretaria,
  },
];

export default parecerDescritivoRoutes;
