import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaProfessores = lazy(() => import("./consulta"));
const CadastroProfessor = lazy(() => import("./cadastro"));

const professorRoutes = [
  {
    path: "/professor/consulta",
    component: ConsultaProfessores,
    auth: authRoles.secretaria,
  },
  {
    path: "/professor/cadastro",
    component: CadastroProfessor,
    auth: authRoles.secretaria,
  },
];

export default professorRoutes;