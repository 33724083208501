import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Recados = lazy(() => import("./recados"));

const recadoRoutes = [
  {
    path: "/recado/recados",
    component: Recados,
    auth: authRoles.secretaria,
  },
];

export default recadoRoutes;