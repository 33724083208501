import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaServicos = lazy(() => import("./consulta"));
const CadastroServico = lazy(() => import("./cadastro"));

const servicoRoutes = [
  {
    path: "/servico/consulta",
    component: ConsultaServicos,
    auth: authRoles.financeiro,
  },
  {
    path: "/servico/cadastro",
    component: CadastroServico,
    auth: authRoles.financeiro,
  },
];

export default servicoRoutes;