import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaTitulo = lazy(() => import("./consulta"));

const tituloRoutes = [
  {
    path: "/titulo/consulta",
    component: ConsultaTitulo,
    auth: authRoles.financeiro,
  },
];

export default tituloRoutes;
