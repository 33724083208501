import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroMensagem = lazy(() => import("./cadastro"));

const mensagemRoutes = [
  {
    path: "/mensagem/cadastro",
    component: CadastroMensagem,
    auth: authRoles.secretaria,
  },
];

export default mensagemRoutes;