import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroTurno = lazy(() => import("./cadastro"));
const ConsultaTurno = lazy(() => import("./consulta"));

const turnoRoutes = [
  {
    path: "/turno/cadastro",
    component: CadastroTurno,
    auth: authRoles.professor,
  },
  {
    path: "/turno/consulta",
    component: ConsultaTurno,
    auth: authRoles.professor,
  },
];

export default turnoRoutes;
