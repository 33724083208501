import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroModeloContrato = lazy(() => import("./cadastro"));

const contratoRoutes = [
  {
    path: "/contrato/cadastro",
    component: CadastroModeloContrato,
    auth: authRoles.secretaria,
  },
];

export default contratoRoutes;