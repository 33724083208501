import React from "react";
import { Redirect } from "react-router-dom";
import sessionsRoutes from "./views/sessions/sessionRoutes";
import AuthGuard from "./auth/AuthGuard";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import turnoRoutes from "./views/turno/turnoRoutes";
import turmaRoutes from "./views/turma/turmaRoutes";
import alunoRoutes from "./views/aluno/alunoRoutes";
import calendarioRoutes from "./views/calendario/calendarioRoutes";
import bibliotecaRoutes from "./views/biblioteca/bibliotecaRoutes";
import consultaRoutes from "./views/consultas/consultaRoutes";
import convenioRoutes from "./views/convenio/convenioRoutes";
import visitaRoutes from "./views/visita/visitaRoutes";
import planejamentoRoutes from "./views/planejamento/planejamentoRoutes";
import professorRoutes from "./views/professor/professorRoutes";
import atividadeRoutes from "./views/atividade/atividadeRoutes";
import refeicaoRoutes from "./views/refeicao/refeicaoRoutes";
import cardapioRoutes from "./views/cardapio/cardapioRoutes";
import comunicadoRoutes from "./views/comunicado/comunicadoRoutes";
import forncedorRoutes from "./views/fornecedor/fornecedorRoutes";
import contacontabilRoutes from "./views/contacontabil/contacontabilRoutes";
import contapagarRoutes from "./views/contapagar/contapagarRoutes";
import contareceberRoutes from "./views/contareceber/contareceberRoutes";
import servicoRoutes from "./views/servico/servicoRoutes";
import escolaRoutes from "./views/escola/escolaRoutes";
import usuarioRoutes from "./views/usuario/usuarioRoutes";
import livrocaixaRoutes from "./views/livrocaixa/livrocaixaRoutes";
import portadorRoutes from "./views/portador/portadorRoutes";
import contratoRoutes from "./views/contrato/contratoRoutes";
import chamadaRoutes from "./views/chamada/chamadaRoutes";
import tituloRoutes from "./views/titulo/tituloRoutes";
import boletosRoutes from "./views/boletos/boletosRoutes";
import mensagemRoutes from "./views/mensagem/mensagemRoutes";
import rematriculaRoutes from "./views/rematricula/rematriculaRoutes";
import marketingRoutes from "./views/marketing/marketingRoutes";
import parecerDescritivoRoutes from "./views/parecerdescritivo/parecerDescritivoRoutes";
import moduloProfessor from "./views/ModuloProfessor/moduloProfessorRoutes";
import consideracoesRoutes from "./views/consideracao/consideracoesRoutes";
import recadoRoutes from "./views/recado/recadoRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/sessions/login" />,
  },
];

const redirectNotFound = [
  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/sessions/login" />,
  },
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...turnoRoutes,
      ...turmaRoutes,
      ...alunoRoutes,
      ...calendarioRoutes,
      ...redirectRoute,
      ...bibliotecaRoutes,
      ...consultaRoutes,
      ...convenioRoutes,
      ...visitaRoutes,
      ...planejamentoRoutes,
      ...professorRoutes,
      ...atividadeRoutes,
      ...refeicaoRoutes,
      ...cardapioRoutes,
      ...comunicadoRoutes,
      ...forncedorRoutes,
      ...contacontabilRoutes,
      ...contapagarRoutes,
      ...contareceberRoutes,
      ...servicoRoutes,
      ...escolaRoutes,
      ...usuarioRoutes,
      ...livrocaixaRoutes,
      ...portadorRoutes,
      ...contratoRoutes,
      ...chamadaRoutes,      
      ...tituloRoutes,
      ...boletosRoutes,
      ...mensagemRoutes,
      ...marketingRoutes,
      ...rematriculaRoutes,
      ...parecerDescritivoRoutes,
      ...moduloProfessor,
      ...consideracoesRoutes,
      ...recadoRoutes,
      ...redirectNotFound,
    ],
  },
];

export default routes;
