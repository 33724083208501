import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CadastroChamada = lazy(() => import("./cadastro"));

const chamadaRoutes = [
  {
    path: "/chamada/cadastro",
    component: CadastroChamada,
    auth: authRoles.professor,
  }
];

export default chamadaRoutes;
